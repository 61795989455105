<script lang="ts" setup>
import { ref, computed } from "vue";
import { api_call_no_key, Verb } from "../api.ts";

const pin = ref("");

const pin_valid = computed(() => {
  return /^[0-9]{6}$/.exec(pin.value);
});

const message = ref("");

const login = () => {
  api_call_no_key("/api/users/pin", Verb.POST, {
    pin: pin.value,
  }).then((data) => {
    if (data.two_fa_required) {
      message.value = "Bad PIN";
    } else {
      if (data.broker) {
        window.location.href = "/dashboard/";
      } else {
        window.location.href = "/company/";
      }
    }
  });
};
</script>
<template>
  <div class="gray-box">
    <h5>Lets get you started with Flagstone.</h5>
    <div class="stacked-form">
      <div>
        <label for="pin">PIN</label>
        <input type="text" placeholder="PIN" v-model="pin" />
      </div>
    </div>
    <div class="additional-links">
      <router-link to="/request_pin">Request PIN Again.</router-link>
      <a href="/welcome/logout/">Log out</a>
    </div>
  </div>
  <div class="buttons-nav">
    <button
      class="large"
      @click="login"
      :disabled="!pin_valid"
      id="login-button"
    >
      Log In
    </button>
  </div>
  <div>
    <p v-if="message">{{ message }}</p>
  </div>
</template>
<style scoped>
button:disabled {
  background-color: #ccc;
}
div.form-input {
  gap: 8px;
  width: 100%;
}
div.form-input label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
div.p-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
</style>
