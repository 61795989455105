<script lang="ts" setup>
import { inject, ref, computed } from "vue";
import { api_call_no_key, Verb, get_login_state } from "../api.ts";
import { email_valid as raw_email_valid } from "./utils.ts";
import { Router } from "vue-router";

const login_state = await get_login_state();
const router = inject("router") as Router;

const email = ref(login_state.email);

const password = ref("");

const email_valid = computed(() => {
  return raw_email_valid(email.value);
});

const message = ref("");

const ready_to_submit = computed(function () {
  if (email_valid.value) {
    return password.value;
  } else {
    return email_valid.value && password.value;
  }
});

const login = () => {
  api_call_no_key("/api/users/login", Verb.POST, {
    email: email.value,
    password: password.value,
  }).then((data) => {
    console.log(data, "data!");
    if (data && data.twofa_required) {
      router.push("/request_pin");
    } else if (!data.twofa_required && !data.password_required) {
      if (data.broker) {
        window.location.href = "/dashboard/";
      } else {
        window.location.href = "/company/";
      }
    } else {
      message.value = "Login failed";
    }
  });
};
</script>
<template>
  <div class="gray-box">
    <h5>Lets get you started with Flagstone.</h5>
    <div class="stacked-form">
      <div>
        <label for="email">Email</label>
        <input type="email" v-model="email" placeholder="you@example.com" />
      </div>
      <div>
        <label for="password">Password</label>
        <input type="password" placeholder="Password" v-model="password" />
      </div>
    </div>
    <div class="additional-links">
      <router-link to="/request_reset"
        >I need to reset my password.</router-link
      >
      <a v-if="!login_state.twofa_required" href="/welcome/forget_computer/"
        >Forget this computer</a
      >
    </div>
  </div>
  <div class="buttons-nav">
    <button
      class="large"
      @click="login"
      :disabled="!ready_to_submit"
      id="login-button"
    >
      Log In
    </button>
  </div>
  <div>
    <p v-if="message">{{ message }}</p>
  </div>
</template>
<style scoped>
button:disabled {
  background-color: #ccc;
}
div.p-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
</style>
