<script setup lang="ts">
import { ref, computed } from "vue";
import { api_call_no_key, get_login_state, Verb } from "../api.ts";
import { email_valid as raw_email_valid } from "./utils.ts";

const login_state = await get_login_state();

const email = ref(login_state.email ? login_state.email : "");
const message = ref("");

const email_valid = computed(() => {
  return raw_email_valid(email.value);
});

const reset_password = () => {
  api_call_no_key(
    "/welcome/request_password_reset/?email=" + encodeURIComponent(email.value),
    Verb.POST,
    {},
  ).then((data) => {
    if (data) {
      message.value = "Password reset email sent";
    } else {
      message.value = "Password reset failed";
    }
  });
};
</script>
<template>
  <div class="gray-box">
    <h5>Request Password Reset</h5>
    <div class="form-columns">
      <div class="form-input">
        <label for="password">Email</label>
        <input type="email" id="email" v-model="email" />
      </div>
    </div>

    <div class="additional-links">
      <router-link to="/">Log In.</router-link>
    </div>
  </div>
  <div>
    <p v-if="message">{{ message }}</p>
  </div>
  <div class="buttons-nav">
    <button class="large" @click="reset_password" :disabled="!email_valid">
      Request Reset Password
    </button>
  </div>
</template>
