<script setup lang="ts"></script>
<template>
  <div>
    <h3>Sorry, this invite is no longer valid</h3>
    <p>
      Your invitation has either expired or has already been used! If you've
      already started your Harvest profile, please
      <router-link to="/request_pin">log in</router-link>!
    </p>
    <p>
      <router-link to="/request_pin" class="button-large"
        >Resend Invite Email</router-link
      >
    </p>
  </div>
</template>
<style scoped>
.button-large {
  display: inline-block;
}
div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
