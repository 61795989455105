<script setup lang="ts">
import { ref, computed, inject } from "vue";
import { mapValues, reduce } from "lodash";
import { Router } from "vue-router";
import { api_call_no_key, Verb } from "../api.ts";
import { default as CellPhone } from "../widgets/CellPhone.vue";

const router = inject("router") as Router;
const invite_token = inject("invitation_token") as string;

const invite_details = ref(
  await api_call_no_key(
    "/api/users/check_invitation?token=" + invite_token,
    Verb.GET,
    {},
  ),
);

const incomplete = ref(false);

const creation_form = ref({
  email: invite_details.value?.email,
  full_name: invite_details.value?.full_name,
  agreed_to_terms: false,
  invite_token: invite_token,
  cell_phone: invite_details.value?.cell_phone,
  password: "",
});

const errors = computed(() => {
  return mapValues(creation_form.value, (value) => {
    return !value && incomplete.value;
  });
});

if (invite_details.value?.status != "OPEN") {
  router.push("/invite_expired");
}

function create_user() {
  console.log(creation_form.value);
  incomplete.value = reduce(
    creation_form.value,
    (result, value) => {
      return !value || result;
    },
    false,
  );

  if (incomplete.value) {
    return;
  }

  api_call_no_key(
    "/api/users/create_user",
    Verb.POST,
    creation_form.value,
  ).then((data) => {
    if (data != null) {
      window.location.href = "/company/";
      return;
    }
    console.log("It failed!"); // TODO: Show error message
  });
}
</script>
<template>
  <div>
    <h3 class="underlined-header">
      <img src="/assets/login/WelcomeFlower.svg" /> Welcome to Flagstone!
    </h3>
    <p class="large-text">
      Flagstone helps you and your business broker prepare to sell your
      business. We gather financial data, verify your financials and provide a
      view of what your business may be worth. We help you view your business as
      a prospective buyer will.
    </p>
    <div id="form" class="gray-box">
      <h5 class="strong">Let's get you registered.</h5>
      <div class="form-input">
        <label for="email"
          >Your email address <span>(this will be your login)</span></label
        >
        <input
          v-model="creation_form.email"
          type="email"
          placeholder="Email"
          :class="{ error: errors.email }"
        />
      </div>
      <div class="form-input">
        <label for="cell_phone">Cell Phone</label>
        <CellPhone v-model="creation_form.cell_phone" id="cell_phone" />
      </div>
      <div class="form-input">
        <label for="full_name">Your Full Name</label>
        <input
          v-model="creation_form.full_name"
          type="text"
          placeholder="Full Name"
          :class="{ error: errors.full_name }"
        />
      </div>
      <div class="form-input">
        <label for="password">Your Password</label>
        <input
          v-model="creation_form.password"
          type="password"
          placeholder="Password"
          :class="{ error: errors.password }"
        />
      </div>
    </div>
    <div id="terms-box">
      <input v-model="creation_form.agreed_to_terms" type="checkbox" />
      <label for="agreed_to_terms" :class="{ error: errors.agreed_to_terms }"
        >By selecting Create Account, you agree to and have read & acknowledged
        our
        <a href="https://www.withflagstone.com/content/terms">Terms</a>.</label
      >
    </div>
    <button class="large" @click="create_user">Create Account</button>
  </div>
</template>
<style scoped>
div#form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;
}
div#form h5 {
  width: 95%;
  margin-bottom: 20px;
}
div.form-input {
  width: 45%;
}
div.form-input input {
  width: 100%;
  height: 43px;
  padding-left: 10px;
  padding-right: 10px;
}

#terms-box {
  padding: 20px 0;
  border-radius: 8px;
}

#terms-box a {
  text-decoration: underline;
  font-weight: 600;
}
.error {
  color: red;
  border: 1px solid red;
}

#terms-box .error {
  border: none;
}
</style>
