import { createApp } from "vue";
import { default as RootWrapper } from "./RootWrapper.vue";
import { default as Login } from "./Login.vue";
import { default as NewUser } from "./NewUser.vue";
import { default as EnterPin } from "./EnterPin.vue";
import { default as InviteExpired } from "./InviteExpired.vue";
import { default as ResetPassword } from "./ResetPassword.vue";
import { default as RequestReset } from "./RequestReset.vue";
import { default as RequestPin } from "./RequestPin.vue";
import { initialize_sentry } from "../sentry.ts";
import { createRouter, createWebHashHistory } from "vue-router";
import { get_login_state } from "../api.ts";

const app = createApp(RootWrapper);
initialize_sentry(app);

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: "/", component: Login },
    { path: "/request_pin", component: RequestPin },
    { path: "/enter_pin", component: EnterPin },

    { path: "/invite", component: NewUser },
    { path: "/invite_expired", component: InviteExpired },

    { path: "/request_reset", component: RequestReset },
    { path: "/reset", component: ResetPassword },
  ],
});
app.use(router);

const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const invitation_token = params.get("invitation_token");
const reset_token = params.get("reset_token");

app.provide("invitation_token", invitation_token);
app.provide("reset_token", reset_token);
app.provide("router", router);

console.log("tokens", reset_token, invitation_token);

if (reset_token) {
  router.push("/reset");
  app.mount("#app");
} else if (invitation_token) {
  router.push("/invite");
  app.mount("#app");
} else {
  get_login_state().then((data) => {
    if (url.hash == "#/request_reset") {
      app.provide("login_data", data);
      app.mount("#app");
    } else if (data.password_required) {
      app.provide("login_state", data);
      router.push("/");
      app.mount("#app");
    } else if (data.twofa_required) {
      app.provide("login_state", data);
      router.push("/request_pin");
      app.mount("#app");
    } else if (data.admin) {
      window.location.href = "/admin/";
    } else if (data.broker) {
      window.location.href = "/dashboard/";
    } else {
      window.location.href = "/company/";
    }
  });
}
