<script setup lang="ts">
import { inject } from "vue";
import { api_call_no_key, Verb, get_login_state } from "../api.ts";
import { Router } from "vue-router";

const login_state = await get_login_state();
const router = inject("router") as Router;

const request_pin = () => {
  api_call_no_key("/api/users/request_pin", Verb.POST, {
    method: "EMAIL",
  }).then(() => {
    router.push("/enter_pin");
  });
};
</script>
<template>
  <div class="gray-box">
    <h5>Request PIN</h5>
    <p>
      We will send an email to {{ login_state.email }} with a PIN to log in.
    </p>
  </div>
  <div class="buttons-nav">
    <button id="request-pin-button" class="large" @click="request_pin">
      Request Login PIN
    </button>
    <a href="/welcome/logout/">This isn't me, log out</a>
  </div>
</template>
