<script setup lang="ts">
import { ref, inject } from "vue";
import { api_call_no_key, Verb } from "../api.ts";

const password = ref("");
const password_confirm = ref("");

const reset_token = inject("reset_token") as string;

const reset_password = () => {
  if (password.value != password_confirm.value) {
    alert("Passwords do not match!");
    return;
  }
  api_call_no_key("/welcome/change_password/", Verb.POST, {
    password: password.value,
    change_token: reset_token,
  }).then((data) => {
    if (data) {
      window.location.href = "/";
      return;
    }
    alert("Failure to reset password.");
  });
};
</script>
<template>
  <div class="gray-box">
    <h5>Reset Password</h5>
    <div class="form-columns">
      <div class="form-input">
        <label for="password">New Password</label>
        <input type="password" id="password" v-model="password" />
      </div>
      <div class="form-input">
        <label for="password_confirm">Confirm New Password</label>
        <input
          type="password"
          id="password_confirm"
          v-model="password_confirm"
        />
      </div>
    </div>
  </div>
  <div class="buttons-nav">
    <button class="large" @click="reset_password">Reset Password</button>
  </div>
</template>
